import _toDate2 from "./lib/toDate";
import _toFloat2 from "./lib/toFloat";
import _toInt2 from "./lib/toInt";
import _toBoolean2 from "./lib/toBoolean";
import _equals2 from "./lib/equals";
import _contains2 from "./lib/contains";
import _matches2 from "./lib/matches";
import _isEmail2 from "./lib/isEmail";
import _isURL2 from "./lib/isURL";
import _isMACAddress2 from "./lib/isMACAddress";
import _isIP2 from "./lib/isIP";
import _isIPRange2 from "./lib/isIPRange";
import _isFQDN2 from "./lib/isFQDN";
import _isDate2 from "./lib/isDate";
import _isBoolean2 from "./lib/isBoolean";
import _isLocale2 from "./lib/isLocale";
import _isAlpha2 from "./lib/isAlpha";
import _isAlphanumeric2 from "./lib/isAlphanumeric";
import _isNumeric2 from "./lib/isNumeric";
import _isPassportNumber2 from "./lib/isPassportNumber";
import _isPort2 from "./lib/isPort";
import _isLowercase2 from "./lib/isLowercase";
import _isUppercase2 from "./lib/isUppercase";
import _isIMEI2 from "./lib/isIMEI";
import _isAscii2 from "./lib/isAscii";
import _isFullWidth2 from "./lib/isFullWidth";
import _isHalfWidth2 from "./lib/isHalfWidth";
import _isVariableWidth2 from "./lib/isVariableWidth";
import _isMultibyte2 from "./lib/isMultibyte";
import _isSemVer2 from "./lib/isSemVer";
import _isSurrogatePair2 from "./lib/isSurrogatePair";
import _isInt2 from "./lib/isInt";
import _isFloat2 from "./lib/isFloat";
import _isDecimal2 from "./lib/isDecimal";
import _isHexadecimal2 from "./lib/isHexadecimal";
import _isOctal2 from "./lib/isOctal";
import _isDivisibleBy2 from "./lib/isDivisibleBy";
import _isHexColor2 from "./lib/isHexColor";
import _isRgbColor2 from "./lib/isRgbColor";
import _isHSL2 from "./lib/isHSL";
import _isISRC2 from "./lib/isISRC";
import _isIBAN2 from "./lib/isIBAN";
import _isBIC2 from "./lib/isBIC";
import _isMD2 from "./lib/isMD5";
import _isHash2 from "./lib/isHash";
import _isJWT2 from "./lib/isJWT";
import _isJSON2 from "./lib/isJSON";
import _isEmpty2 from "./lib/isEmpty";
import _isLength2 from "./lib/isLength";
import _isByteLength2 from "./lib/isByteLength";
import _isUUID2 from "./lib/isUUID";
import _isMongoId2 from "./lib/isMongoId";
import _isAfter2 from "./lib/isAfter";
import _isBefore2 from "./lib/isBefore";
import _isIn2 from "./lib/isIn";
import _isCreditCard2 from "./lib/isCreditCard";
import _isIdentityCard2 from "./lib/isIdentityCard";
import _isEAN2 from "./lib/isEAN";
import _isISIN2 from "./lib/isISIN";
import _isISBN2 from "./lib/isISBN";
import _isISSN2 from "./lib/isISSN";
import _isTaxID2 from "./lib/isTaxID";
import _isMobilePhone2 from "./lib/isMobilePhone";
import _isEthereumAddress2 from "./lib/isEthereumAddress";
import _isCurrency2 from "./lib/isCurrency";
import _isBtcAddress2 from "./lib/isBtcAddress";
import _isISO3 from "./lib/isISO8601";
import _isRFC2 from "./lib/isRFC3339";
import _isISO31661Alpha3 from "./lib/isISO31661Alpha2";
import _isISO31661Alpha4 from "./lib/isISO31661Alpha3";
import _isISO4 from "./lib/isISO4217";
import _isBase4 from "./lib/isBase32";
import _isBase5 from "./lib/isBase58";
import _isBase6 from "./lib/isBase64";
import _isDataURI2 from "./lib/isDataURI";
import _isMagnetURI2 from "./lib/isMagnetURI";
import _isMimeType2 from "./lib/isMimeType";
import _isLatLong2 from "./lib/isLatLong";
import _isPostalCode2 from "./lib/isPostalCode";
import _ltrim2 from "./lib/ltrim";
import _rtrim2 from "./lib/rtrim";
import _trim2 from "./lib/trim";
import _escape2 from "./lib/escape";
import _unescape2 from "./lib/unescape";
import _stripLow2 from "./lib/stripLow";
import _whitelist2 from "./lib/whitelist";
import _blacklist2 from "./lib/blacklist";
import _isWhitelisted2 from "./lib/isWhitelisted";
import _normalizeEmail2 from "./lib/normalizeEmail";
import _isSlug2 from "./lib/isSlug";
import _isLicensePlate2 from "./lib/isLicensePlate";
import _isStrongPassword2 from "./lib/isStrongPassword";
import _isVAT2 from "./lib/isVAT";
var exports = {};

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toDate = _interopRequireDefault(_toDate2);

var _toFloat = _interopRequireDefault(_toFloat2);

var _toInt = _interopRequireDefault(_toInt2);

var _toBoolean = _interopRequireDefault(_toBoolean2);

var _equals = _interopRequireDefault(_equals2);

var _contains = _interopRequireDefault(_contains2);

var _matches = _interopRequireDefault(_matches2);

var _isEmail = _interopRequireDefault(_isEmail2);

var _isURL = _interopRequireDefault(_isURL2);

var _isMACAddress = _interopRequireDefault(_isMACAddress2);

var _isIP = _interopRequireDefault(_isIP2);

var _isIPRange = _interopRequireDefault(_isIPRange2);

var _isFQDN = _interopRequireDefault(_isFQDN2);

var _isDate = _interopRequireDefault(_isDate2);

var _isBoolean = _interopRequireDefault(_isBoolean2);

var _isLocale = _interopRequireDefault(_isLocale2);

var _isAlpha = _interopRequireWildcard(_isAlpha2);

var _isAlphanumeric = _interopRequireWildcard(_isAlphanumeric2);

var _isNumeric = _interopRequireDefault(_isNumeric2);

var _isPassportNumber = _interopRequireDefault(_isPassportNumber2);

var _isPort = _interopRequireDefault(_isPort2);

var _isLowercase = _interopRequireDefault(_isLowercase2);

var _isUppercase = _interopRequireDefault(_isUppercase2);

var _isIMEI = _interopRequireDefault(_isIMEI2);

var _isAscii = _interopRequireDefault(_isAscii2);

var _isFullWidth = _interopRequireDefault(_isFullWidth2);

var _isHalfWidth = _interopRequireDefault(_isHalfWidth2);

var _isVariableWidth = _interopRequireDefault(_isVariableWidth2);

var _isMultibyte = _interopRequireDefault(_isMultibyte2);

var _isSemVer = _interopRequireDefault(_isSemVer2);

var _isSurrogatePair = _interopRequireDefault(_isSurrogatePair2);

var _isInt = _interopRequireDefault(_isInt2);

var _isFloat = _interopRequireWildcard(_isFloat2);

var _isDecimal = _interopRequireDefault(_isDecimal2);

var _isHexadecimal = _interopRequireDefault(_isHexadecimal2);

var _isOctal = _interopRequireDefault(_isOctal2);

var _isDivisibleBy = _interopRequireDefault(_isDivisibleBy2);

var _isHexColor = _interopRequireDefault(_isHexColor2);

var _isRgbColor = _interopRequireDefault(_isRgbColor2);

var _isHSL = _interopRequireDefault(_isHSL2);

var _isISRC = _interopRequireDefault(_isISRC2);

var _isIBAN = _interopRequireWildcard(_isIBAN2);

var _isBIC = _interopRequireDefault(_isBIC2);

var _isMD = _interopRequireDefault(_isMD2);

var _isHash = _interopRequireDefault(_isHash2);

var _isJWT = _interopRequireDefault(_isJWT2);

var _isJSON = _interopRequireDefault(_isJSON2);

var _isEmpty = _interopRequireDefault(_isEmpty2);

var _isLength = _interopRequireDefault(_isLength2);

var _isByteLength = _interopRequireDefault(_isByteLength2);

var _isUUID = _interopRequireDefault(_isUUID2);

var _isMongoId = _interopRequireDefault(_isMongoId2);

var _isAfter = _interopRequireDefault(_isAfter2);

var _isBefore = _interopRequireDefault(_isBefore2);

var _isIn = _interopRequireDefault(_isIn2);

var _isCreditCard = _interopRequireDefault(_isCreditCard2);

var _isIdentityCard = _interopRequireDefault(_isIdentityCard2);

var _isEAN = _interopRequireDefault(_isEAN2);

var _isISIN = _interopRequireDefault(_isISIN2);

var _isISBN = _interopRequireDefault(_isISBN2);

var _isISSN = _interopRequireDefault(_isISSN2);

var _isTaxID = _interopRequireDefault(_isTaxID2);

var _isMobilePhone = _interopRequireWildcard(_isMobilePhone2);

var _isEthereumAddress = _interopRequireDefault(_isEthereumAddress2);

var _isCurrency = _interopRequireDefault(_isCurrency2);

var _isBtcAddress = _interopRequireDefault(_isBtcAddress2);

var _isISO = _interopRequireDefault(_isISO3);

var _isRFC = _interopRequireDefault(_isRFC2);

var _isISO31661Alpha = _interopRequireDefault(_isISO31661Alpha3);

var _isISO31661Alpha2 = _interopRequireDefault(_isISO31661Alpha4);

var _isISO2 = _interopRequireDefault(_isISO4);

var _isBase = _interopRequireDefault(_isBase4);

var _isBase2 = _interopRequireDefault(_isBase5);

var _isBase3 = _interopRequireDefault(_isBase6);

var _isDataURI = _interopRequireDefault(_isDataURI2);

var _isMagnetURI = _interopRequireDefault(_isMagnetURI2);

var _isMimeType = _interopRequireDefault(_isMimeType2);

var _isLatLong = _interopRequireDefault(_isLatLong2);

var _isPostalCode = _interopRequireWildcard(_isPostalCode2);

var _ltrim = _interopRequireDefault(_ltrim2);

var _rtrim = _interopRequireDefault(_rtrim2);

var _trim = _interopRequireDefault(_trim2);

var _escape = _interopRequireDefault(_escape2);

var _unescape = _interopRequireDefault(_unescape2);

var _stripLow = _interopRequireDefault(_stripLow2);

var _whitelist = _interopRequireDefault(_whitelist2);

var _blacklist = _interopRequireDefault(_blacklist2);

var _isWhitelisted = _interopRequireDefault(_isWhitelisted2);

var _normalizeEmail = _interopRequireDefault(_normalizeEmail2);

var _isSlug = _interopRequireDefault(_isSlug2);

var _isLicensePlate = _interopRequireDefault(_isLicensePlate2);

var _isStrongPassword = _interopRequireDefault(_isStrongPassword2);

var _isVAT = _interopRequireDefault(_isVAT2);

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var version = "13.7.0";
var validator = {
  version: version,
  toDate: _toDate.default,
  toFloat: _toFloat.default,
  toInt: _toInt.default,
  toBoolean: _toBoolean.default,
  equals: _equals.default,
  contains: _contains.default,
  matches: _matches.default,
  isEmail: _isEmail.default,
  isURL: _isURL.default,
  isMACAddress: _isMACAddress.default,
  isIP: _isIP.default,
  isIPRange: _isIPRange.default,
  isFQDN: _isFQDN.default,
  isBoolean: _isBoolean.default,
  isIBAN: _isIBAN.default,
  isBIC: _isBIC.default,
  isAlpha: _isAlpha.default,
  isAlphaLocales: _isAlpha.locales,
  isAlphanumeric: _isAlphanumeric.default,
  isAlphanumericLocales: _isAlphanumeric.locales,
  isNumeric: _isNumeric.default,
  isPassportNumber: _isPassportNumber.default,
  isPort: _isPort.default,
  isLowercase: _isLowercase.default,
  isUppercase: _isUppercase.default,
  isAscii: _isAscii.default,
  isFullWidth: _isFullWidth.default,
  isHalfWidth: _isHalfWidth.default,
  isVariableWidth: _isVariableWidth.default,
  isMultibyte: _isMultibyte.default,
  isSemVer: _isSemVer.default,
  isSurrogatePair: _isSurrogatePair.default,
  isInt: _isInt.default,
  isIMEI: _isIMEI.default,
  isFloat: _isFloat.default,
  isFloatLocales: _isFloat.locales,
  isDecimal: _isDecimal.default,
  isHexadecimal: _isHexadecimal.default,
  isOctal: _isOctal.default,
  isDivisibleBy: _isDivisibleBy.default,
  isHexColor: _isHexColor.default,
  isRgbColor: _isRgbColor.default,
  isHSL: _isHSL.default,
  isISRC: _isISRC.default,
  isMD5: _isMD.default,
  isHash: _isHash.default,
  isJWT: _isJWT.default,
  isJSON: _isJSON.default,
  isEmpty: _isEmpty.default,
  isLength: _isLength.default,
  isLocale: _isLocale.default,
  isByteLength: _isByteLength.default,
  isUUID: _isUUID.default,
  isMongoId: _isMongoId.default,
  isAfter: _isAfter.default,
  isBefore: _isBefore.default,
  isIn: _isIn.default,
  isCreditCard: _isCreditCard.default,
  isIdentityCard: _isIdentityCard.default,
  isEAN: _isEAN.default,
  isISIN: _isISIN.default,
  isISBN: _isISBN.default,
  isISSN: _isISSN.default,
  isMobilePhone: _isMobilePhone.default,
  isMobilePhoneLocales: _isMobilePhone.locales,
  isPostalCode: _isPostalCode.default,
  isPostalCodeLocales: _isPostalCode.locales,
  isEthereumAddress: _isEthereumAddress.default,
  isCurrency: _isCurrency.default,
  isBtcAddress: _isBtcAddress.default,
  isISO8601: _isISO.default,
  isRFC3339: _isRFC.default,
  isISO31661Alpha2: _isISO31661Alpha.default,
  isISO31661Alpha3: _isISO31661Alpha2.default,
  isISO4217: _isISO2.default,
  isBase32: _isBase.default,
  isBase58: _isBase2.default,
  isBase64: _isBase3.default,
  isDataURI: _isDataURI.default,
  isMagnetURI: _isMagnetURI.default,
  isMimeType: _isMimeType.default,
  isLatLong: _isLatLong.default,
  ltrim: _ltrim.default,
  rtrim: _rtrim.default,
  trim: _trim.default,
  escape: _escape.default,
  unescape: _unescape.default,
  stripLow: _stripLow.default,
  whitelist: _whitelist.default,
  blacklist: _blacklist.default,
  isWhitelisted: _isWhitelisted.default,
  normalizeEmail: _normalizeEmail.default,
  toString: toString,
  isSlug: _isSlug.default,
  isStrongPassword: _isStrongPassword.default,
  isTaxID: _isTaxID.default,
  isDate: _isDate.default,
  isLicensePlate: _isLicensePlate.default,
  isVAT: _isVAT.default,
  ibanLocales: _isIBAN.locales
};
var _default = validator;
exports.default = _default;
exports = exports.default;
exports.default = exports.default;
export default exports;
export const __esModule = exports.__esModule;